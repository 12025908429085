<template>
  <template v-if="!loading">
    <LandingSafety v-if="isSafetyCatalog" />
    <LandingVision v-else />
    <ClientOnly>
      <FittingboxModal />
    </ClientOnly>
  </template>
  <template v-else>
    <ZnLoadingBar />
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRouter } from '#imports'
import { useGuestStore } from '~/store/guest'

const router = useRouter()

const { isSafetyCatalog, loading, isGuestMode, guestStoreUrl } = storeToRefs(
  useGuestStore(),
)
if (isGuestMode.value) {
  router.replace(guestStoreUrl.value)
}
</script>
